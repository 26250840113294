<template>
    <div>
        <Navbar page="Assinatura" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <h1 class="font-r font-medium text-3xl mb-8">Cookie Banner da Fácil LGPD</h1>
        </div>

        <div class="max-w-md mx-auto">
            <form action="">
                <div>
                    <label for="name">Nome</label>
                    <input type="text" id="name" class="mt-2 mb-2 px-2 border-2 border-gray-300 rounded-lg w-full h-10 shadow-sm focus:ring-yellow-400 focus:border-yellow-400">
                </div>

                <div class="my-2" id="payment-element" ref="paymentElement">
                    <!-- the card element will be mounted here -->
                </div>

                <button @click="pay" type="button" class="text-white hover:bg-green-500 bg-green-700 rounded py-2 px-6">Assinar</button>
            </form>
        </div>


    </div>
</template>

<script>
    let elements
    let stripe

    export default {
        data() {
            return {
                route: 'stripe',
                user: null,
                subscriptionClientSecret: null
            }
        },
        methods: {
            async start() {
                const configReq = await this.$http.get(`/v1/${this.route}/config`);
                const configSub = await this.$http.post(`/v1/${this.route}/subscribe`, { priceId: this.$route.params.priceid })

                this.subscriptionClientSecret = configSub.data.subscriptionClientSecret

                stripe = window.Stripe(configReq.data.publishableKey);

                elements = stripe.elements({ clientSecret: configSub.data.subscriptionClientSecret })

                const paymentElement = elements.create('payment', {
                    paymentMethodOrder: ['card']
                })

                paymentElement.mount('#payment-element')
            },
            async pay() {
                const { error } = await stripe.confirmPayment({
                    elements,
                    confirmParams: {
                        return_url: `${window.location.origin}/stripe/return`
                    }
                });

                if (error.type === "card_error" || error.type === "validation_error") {
                    console.log(error);
                    console.log(error.message);
                    this.$vToastify.error(error.message);
                } else {
                    console.log(error);
                    console.log("An unexpected error occured.");
                }
            }
        },

        async beforeMount() {
            this.start();
        }
    }
</script>